import React, { Component } from "react"
import { Link, graphql, StaticQuery } from 'gatsby';
import Layout from '../components/Layout';
import Header from '../components/Header';
import SEO from '../components/SEO';
import '../styles/components/error.scss';

class NotFoundPage extends React.Component {

  render() {

    return (
      <>
        <StaticQuery 
          query={graphql`
          query Error {
            wp {
              generalSettings {
                title
                description
              }
              siteSettings {
                settings {
                  address
                  email
                  phone
                }
              }
            }
          }
          `}
          render={(error) => (
            <>
              <Layout>
                <SEO
                  bodyClasses="error"
                  siteTitle={ error.wp.generalSettings.title }
                  siteDescription={ error.wp.generalSettings.description }
                  title={ "404" } 
                />
                <Header />

                <div className={ 'text:section error-content' }>
                  <div className={ 'flex' }>
                    <h2>404</h2>
                    <p>Page not found &mdash; 
                      <Link to={ '/' }> return home</Link>
                    </p>
                  </div>
                </div>
              </Layout>
            </>
          )}
        />
      </>
    );

  }

}

export default NotFoundPage;

